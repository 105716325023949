import {
  fetchActiveWeapons,
  fetchVaultedWeapons,
} from "@/game-fortnite/fetches/static.mjs";

function fetchData() {
  return Promise.all([fetchVaultedWeapons(), fetchActiveWeapons()]);
}

export default fetchData;
